@font-face {
    font-family: 'GothamBook';
    src: url('../public/fonts/Gotham-Book.woff2') format('woff2'),
    url('../public/fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamMedium';
    src: url('../public/fonts/Gotham-Medium.woff2') format('woff2'),
    url('../public/fonts/Gotham-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

body {
    background-color: #000;
}

.App {
    color: #fff;
    min-height: 100vh;
    overflow-y: hidden;
    position: relative;
    font-family: 'GothamBook', sans-serif;
}

.grecaptcha-badge {
    visibility: hidden;
    opacity: 0;
    position: absolute;
}
